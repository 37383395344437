.order_detail_table {
  background: #fff;
  padding: 30px 40px;
  .info {
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    .box {
      margin-right: 60px;
      color: #999;
      span {
        color: #333;
      }
    }
  }
}