.filter_box {
  display: flex;
  align-items: center;
  margin: 20px 0 40px 0;
  .brand_box {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-right: 28px;
  }
  .btns {
    display: flex;
    align-items: center;
    margin-left: 100px;
  }
}
:global {
  .table {
    .ant-table-thead > tr > th {
      font-weight: bold !important;
      text-align: center;
    }
    .ant-table-tbody > tr > td {
      text-align: center;
    }
  }
}
.close {
  .modal {
    .title {
      font-size: 17px;
      margin-bottom: 18px;
      text-align: center;
    }
    .btn {
      display: flex;
      justify-content: space-evenly;
    }
  }
}
.alter {
  .modal {
    .title {
      display: flex;
    }
    .ranking {
      margin-top: 20px;
      .content {
        overflow-y: scroll;
        max-height: 300px;
        &::-webkit-scrollbar {
          width: 0px;
        }
      }
      .box {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 10px;
        
        div {
          text-align: center;
          width: 30%;
        }
      }
    }
    .go_price {
      display: flex;
      padding: 20px 0;
    }
  }
}