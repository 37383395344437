
.filter_box {
  display: flex;
  align-items: center;
  margin: 20px 0 40px 0;
  .brand_box {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-right: 28px;
  }
  .btns {
    display: flex;
    align-items: center;
    margin-left: 100px;
  }
}
.footer {
  margin-top: 50px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .box {
    margin-right: 80px;
  }
  .btn {
    background: #FF3232;
    padding: 12px 40px;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
  }
}
:global {
  .table {
    .ant-table-thead > tr > th {
      font-weight: bold !important;
      text-align: center;
    }
    .ant-table-tbody > tr > td {
      text-align: center;
    }
  }
}
.alter {
  .modal {
    .Logistics {
      div {
        padding: 10px 0;
      }
    }
  }
}