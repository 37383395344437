.shipping_address {
    padding: 25px 40px 50px 40px;
    background-color: #ffffff;
    .address_title {
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 29px;
        color: #333333;
    }
    .box_flex {
        margin-top: 20px;
        .address_info {
            display: flex;
            flex-wrap: wrap;
            .address_bg {
                background: url('https://resources.huanhuanhuishou.com/global_source/C15327BB-6B5C-C0A5-55AB-1FD9088EEAAE.png') no-repeat center;
                background-size: 100% 100%;
                border: 1px solid #ffffff;
            }
            .address_border {
                border: 1px solid #999999;
            }
            .address_box {
                width: 360px;
                height: 130px;
                padding: 20px 25px;
                box-sizing: border-box;
                margin-right: 25px;
                margin-bottom: 15px;
                cursor: pointer;
                .address_city {
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    line-height: 15px;
                    color: #333333;
                }
                .address_iphone {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    line-height: 20px;
                    height: 40px;
                    color: #999999;
                    margin: 10px 0 5px 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .modify {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    line-height: 15px;
                    color: #FF3232;
                    display: flex;
                    justify-content: space-between;
                    span {
                        padding: 5px 7px;
                    }
                }
            }
            .address_add {
                width: 360px;
                height: 130px;
                border: 1px solid #999999;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 15px;
                color: #333333;
                cursor: pointer;
                span {
                    margin-left: 5px;
                }
            }
        }
    }
}

.order_wrap {
    margin-top: 50px;
    background-color: #ffffff;
    padding: 25px 40px 50px 40px;
    .order_title {
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 29px;
        color: #333333;
        margin-bottom: 30px;
    }
    .electronic_invoice {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        background: #F7F7F7;
        padding: 0 25px;
        .shipping_method {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 15px;
            color: #333333;
        }
        .price {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 15px;
            color: #FF3232;
        }
    }
    .total_price {
        height: 60px;
        background: #F7F7F7;
        padding: 0 25px;
        margin-top: 10px;
        text-align: right;
        line-height: 60px;
        .text {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 15px;
            color: #333333;
        }
        .price {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 15px;
            color: #FF3232;
        }
    }
    .submit_orider {
        margin-top: 40px;
        // height: 300px;
        border: 1px solid #999999;
        padding: 30px 0;
        padding-right: 40px;
        box-sizing: border-box;
        text-align: right;
        .actual_payment {
            span:nth-child(1) {
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: bold;
                line-height: 29px;
                color: #333333;
                vertical-align: middle;
            }
            span:nth-child(2) {
                font-size: 30px;
                font-family: PingFang SC;
                font-weight: 800;
                line-height: 42px;
                color: #FF3232;
                vertical-align: middle;
            }
        }
        .address_box_border {
            margin-top: 20px;
            margin-bottom: 40px;
            .order_border {
                display: inline-block;
                background: #FFF5F5;
                border: 1px solid #FFC4C7;
                padding: 10px 20px;
                text-align: left;
                .send {
                    span:nth-child(1) {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        line-height: 15px;
                        color: #999999;
                    }
                    span:nth-child(2) {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        line-height: 15px;
                        color: #333333;
                    }
                }
            }
        }
        .order_sub {
            .submit_bnt {
                display: inline-block;
                width: 200px;
                height: 60px;
                background: #FF3232;
                text-align: center;
                line-height: center;
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: 500;
                line-height: 60px;
                color: #FFFFFF;
                cursor: pointer;
            }
        }
    }
}

:global {
    .ant-modal-footer {
        padding: 0!important;
    }
}
