.footer {
  width: 100%;
  background: #242933;
  color: #818181;
  position: relative;
  .suspension {
    position: absolute;
    left: 0;
    top: -82px;
    z-index: 10;
    width: 100%;
  }
  .footer_img {
	  width: 100%;
  }
  .info {
    padding: 0 300px;
    display: flex;
    padding-top: 42px;
    align-items: center;
    justify-content: space-between;
    .left{
      .phone {
        display: flex;
        align-items: flex-end;
        margin-bottom: 30px;
        .num {
          font-weight: 800;
          margin-left: 6px;
          font-size: 22px;
          color: #FFFFFF;
          font-family: Microsoft YaHei, Microsoft YaHei;
          margin-right: 50px;
        }
      }
      .text {
        margin-top: 12px;
        font-size: 14px;
        color: #FFFFFF;
        opacity: 0.6;
      }
    }
    .right {
      display: flex;
      .code_box {
        text-align: center;
        margin-left: 43px;
        img {
          width: 93px;
        }
        .text {
          margin-top: 10px;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 400;
          font-size: 12px;
          color: #FFFFFF;
        }
      }
    }
  }
  .copyright {
		display: flex;
    padding: 22px 0 20px 0;
    justify-content: center;
		.about_us {
			margin-right: 40px;
			color: transparent;
			cursor: pointer;
		}
    .about_address {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .about_address_1 {
      text-align: left;
      font-size: 14px;
      color: #FFFFFF;
      opacity: 0.6;
    }
  }
}