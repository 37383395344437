.filter_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0 0 0;
  width: 85%;
  .brand_box {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-right: 28px;
    margin-bottom: 20px;
  }
  .btns {
    display: flex;
    align-items: center;
    margin-left: 70px;
    margin-bottom: 20px;
  }
}
.warning {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .tag {
    display: flex;
    .text {
      color: #1890ff;
      margin-left: 20px;
    }
  }
}
:global {
  .table {
    .ant-table-thead > tr > th {
      font-weight: bold !important;
      text-align: center;
    }
    .ant-table-tbody > tr > td {
      text-align: center;
    }
  }
}