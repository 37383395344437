.go_price {
  .modal {
    .text {
      margin-top: 10px;
    }
    .btns {
      display: flex;
      justify-content: space-evenly;
      margin-top: 20px;
    }
  }
}