.heard_nav {
  width: 100%;
  .function {
    padding: 0 300px;
    height: 36px;
    background: #242933;
    display: flex;
    justify-content: flex-end;
    .box {
      height: 100%;
      display: flex;
      align-items: center;
      .item {
        display: flex;
        align-items: center;
        margin-left: 8px;
        .text {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #8A8F99;
          opacity: 0.8;
          cursor: pointer;
          margin-right: 24px;
        }
        .text:hover {
          opacity: 1;
        }
        .br {
          width: 0;
          height: 10px;
          border: 1px solid #616877;
          margin-right: 24px;
          opacity: 0.8;
        }
      }
    }
  }
  .logo_tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 300px;
    height: 74px;
    img {
      width: 180px;
      height: 38px;
    }
    .active_box {
      height: 100%;
      display: flex;
      align-items: center;
      .item {
        padding: 15px 7px 13px 7px;
        margin-left: 18px;
        .text {
          text-align: center;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #242933;
        }
      }
    }
  }
  .carousel {
    width: 100%;
    height: 450px;
    position: relative;
    background: url('https://resources.huanhuanhuishou.com/new_image/20241213/banner-min.png') no-repeat center;
    background-size: 100% 100%;
    .carousel_box {
      a {
        width: 100%;
        img {
          width: 100%;
          height: 420px;
        }
      }
    }
    .info_show {
      position: absolute;
      top: 50%;
      right: 300px;
      width: 280px;
      height: 320px;
      margin-top: -160px;
      display: flex;
      flex-direction: column;
      background: url('https://resources.huanhuanhuishou.com/new_image/20241213/login_bg.png') no-repeat center;
      background-size: 100% 100%;
      border-radius: 20px;
      padding: 0 18px;
      .login_box {
        // height: 156px;
        padding-bottom: 20px;
        text-align: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        .name {
          font-size: 18px;
          font-family: Source Han Sans CN,
            Source Han Sans CN;
          font-weight: 600;
          color: #242933;
          padding: 38px 0 0px 0;
        }
        .btn {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #EEEEEE;
          padding: 7px 15px;
          border-radius: 10px;
          background: #FF3232;
          margin: 0 100px;
          cursor: pointer;
        }
        .loginRegister {
          display: flex;
          justify-content: space-between;
          font-weight: 500;
          font-size: 22px;
          color: #E83936;
          line-height: 26px;
          padding: 0 55px;
          margin-top: 30px;
          div {
            cursor: pointer;
            &:first-child {
              color: #000000;
            }
          }
        }
        .order {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          padding-top: 15px;
          .item {
            text-align: center;
            cursor: pointer;
            .number {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 600;
              color: #FF3232;
              margin-bottom: 8px;
            }
            .text {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 600;
              color: #EEEEEE;
            }
            .text_color {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 600;
              color: #333333;
            }
          }
        }
      }
      .announcement_box {
        flex: 1;
        // background: #fff;
        .title {
          text-align: center;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #000000;
          margin: 20px 0 9px 0;
        }
        .item {
          padding: 0 15px;
          margin-bottom: 6px;
          a {
            display: flex;
            align-items: center;
            .tag {
              width: 6px;
              height: 6px;
              background: #FF3232;
              border-radius: 100%;
              margin-right: 7px;
            }
            .text {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #242933;
            }
          }
        }
      }
    }
  }
  .flow {
    width: 100%;
  }
}