.fixed_price {
  font-family: PingFang SC;
  margin: 0 30px;
  .head_box{
    padding: 20px 30px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .title {
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .filter_box {
      display: flex;
      align-items: center;
      .brand_box {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-right: 28px;
      }
      .btns {
        display: flex;
        align-items: center;
        margin-left: 5px;
      }
    }
  }
  .table_box {
    padding: 20px 30px;
    background: #fff;
    border-radius: 8px;
    :global {
      .table {
        .ant-table-thead > tr > th {
          font-weight: bold !important;
          background: #fff;
          text-align: center;
        }
        .ant-table-tbody > tr > td:nth-child(1) {
          max-width: 240px;
        }
        .ant-table-tbody > tr > td:nth-child(n+2) {
          text-align: center;
        }
      }
    }
  }
  // 议价弹窗
  .bargain_goods {
    display: flex;
    align-items: center;
    .grade_text {
      background: #FF3232;
      border-radius: 100px;
      font-size: 12px;
      color: #FFFFFF;
      padding: 0 6px;
    }
    .goods_name {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      margin-left: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .sku_desc {
    color: #999999;
  }
  .color_price {
    color: #FF3232;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 25px;
  }
}