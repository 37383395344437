.heard_nav {
  width: 100%;
  .function {
    padding: 0 300px;
    height: 36px;
    background: #000;
    display: flex;
    justify-content: flex-end;
    .box {
      height: 100%;
      display: flex;
      align-items: center;
      .item {
        display: flex;
        align-items: center;
        margin-left: 8px;
        .text {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #EEEEEE;
          opacity: 0.8;
          cursor: pointer;
          margin-right: 8px;
        }
        .text:hover {
          opacity: 1;
        }
        .br {
          width: 0;
          height: 10px;
          border: 1px solid #EEEEEE;
          opacity: 0.8;
        }
      }
    }
  }
  .logo_tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 300px;
    height: 74px;
    img {
      width: 207px;
      height: 43px;
    }
    .active_box {
      height: 100%;
      display: flex;
      align-items: center;
      .item {
        padding: 15px 7px 13px 7px;
        margin-left: 18px;
        .text {
          text-align: center;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}