.whisper {
  background:#F6F8FA;
  width: 1800px;
  text-align: center;
  padding: 0 300px;
  padding-top: 50px;
  margin: 0 auto;
  .title {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 800;
    font-size: 28px;
    color: #191818;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .tag {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    margin: 8px 0 19px 0;
  }
  .show {
    cursor: pointer;
    .time {
      background-image: linear-gradient(89deg, #FF3232 , #FF3D7A );
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px 15px 0 0;
      .text {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 29px;
        color: #FFFFFF;
        margin-right: 11px;
        padding: 20px 0;
      }
    }
    .over {
      background-color: #cccccc;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px 15px 0 0;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 29px;
      color: #FFFFFF;
      // margin-right: 11px;
      padding: 20px 0;
    }
    .content {
      padding: 30px;
      background: #fff;
      display: flex;
      .left {
        width: 71%;
        .brand {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          margin-bottom: 6px;
          .brand_item {
            border-radius: 8px;
            cursor: pointer;
            width: 134.3px;
            height: 82px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #ddd;
            .text {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #666666;
              margin-right: 10px;
            }
            img {
              width: 30px;
              height: 30px;
            }
          }
        }
        .site {
          display: flex;
          justify-content: space-between;
          .site_item {
            width: 49%;
            height: 256px;
            cursor: pointer;
          }
        }
      }
      .right {
        width: 27.33%;
        margin-left: 18px;
        .title {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #333333;
          margin: 34px 0 37px 0;
        }
        .hot_brand {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          .hot_brand_item {
            width: 26%;
            margin-bottom: 31px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .dark_shooting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .shooting_mode {
      width: 588px;
      height: 246px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: url('https://resources.huanhuanhuishou.com/new_image/20241213/premium_products.png') no-repeat center;
      background-size: 100% 100%;
      padding: 0 20px 0 30px;
    }
    .shooting_props {
      width: 588px;
      height: 246px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: url('https://resources.huanhuanhuishou.com/new_image/20241213/leaks.png') no-repeat center;
      background-size: 100% 100%;
      padding: 0 20px 0 30px;
    }
    .left_text {
      text-align: left;

      .shooting_title {
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: bold;
        font-size: 32px;
        color: #101012;
        line-height: 38px;
      }

      .shooting_ls {
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 300;
        font-size: 22px;
        color: #666666;
        line-height: 26px;
        margin-top: 10px;
      }

      .shooting_bnt {
        background: #E83A36;
        border-radius: 90px;
        padding: 8px 30px;
        margin-top: 38px;
        cursor: pointer;
      }
      .over {
        background: #C1C1C1;
        border-radius: 90px;
        padding: 8px 30px;
        margin-top: 38px;
        cursor: pointer;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 26px;
        color: #FFFFFF;
        text-align: center;
      }
    }

    .shooting_img {
      img {
        width: 200px;
        height: 200px;
        cursor: pointer;
      }
    }
  }
}