.back_stage {
  height: 100vh;
  .layout {
    height: 100%;
    .logo {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #EEEEEE;
      height: 50px;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
    }
    .service {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      text-align: center;
      padding: 9px 0;
      position: absolute;
      bottom: 0;
      transform: translate(-50%);
      left: 50%;
      img {
        width: 32px;
        height: 32px;
        margin-bottom: 5px;
      }
    }
    .bread_crumd {
      display: flex;
      align-items: center;
      height: 50px;
      background: #fff;
      line-height: 50px;
      padding-left: 40px;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
      .go_back {
        color: #000;
        margin-right: 20px;
        margin-top: 2px;
        cursor: pointer;
      }
    }
  }
  .user_info {
    display: flex;
    justify-content: flex-end;
    padding: 0 60px 0 0;
    font-size: 16px;
    span {
      margin-right: 16px;
    }
    .none_login {
      cursor: pointer;
      &:hover {
        color: #1890ff;
      }
    }
  }
}