.picker {
  background: #F6F8FA;
  width: 1800px;
  text-align: center;
  padding: 0 300px;
  padding-top: 50px;
  margin: 0 auto;
  .title {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 800;
    font-size: 28px;
    color: #191818;
    text-align: left;
  }
  .tag {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    margin: 8px 0 19px 0;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .goods_box {
      height: 325px;
      cursor: pointer;
      width: 24%;
      background: #fff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding: 0 10px;
      padding-bottom: 30px;
      .tag_img {
        position: absolute;
        top: 0;
        left: 20px;
      }
      .degree {
        width: 100%;
        margin: 10px 0 36px 0;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .br {
          border: 1px solid #333333;
          height: 10px;
          margin: 0 5px;
        }
      }
      .goods_img {
        width: 175px;
        height: 175px;
        object-fit: scale-down;
      }
      .name {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        margin: 10px 0 14px 0;
      }
      .time {
        background-image: linear-gradient(88deg, #FF3232 , #FF3D7A );
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 175px;
        padding: 5px 0;
        border-radius: 18px;
      }
    }
  }

  // 改版后代码
  .wrap_box {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 33px 50px 33px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .left_title {
      text-align: left;
      .name_ts {
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: bold;
        font-size: 32px;
        color: #101012;
        line-height: 38px;
      }
      .start_time {
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 300;
        font-size: 22px;
        color: #666666;
        line-height: 26px;
        margin-top: 8px;
      }
      .title_bnt {
        background: #E83A36;
        border-radius: 90px;
        padding: 8px 30px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 26px;
        color: #FFFFFF;
        display: inline-block;
        margin-top: 38px;
        letter-spacing: 2px;
        cursor: pointer;
      }
    }
    .right_images {
      display: flex;
      align-items: center;
      img {
        width: 142px;
        height: 142px;
        margin-left: 30px;
        cursor: pointer;
      }
      .border {
        width: 2px;
        height: 159px;
        background-color: #000000;
        opacity: 0.06;
        margin: 0px 10px 0 40px;
      }
    }
  }
}