.picker {
  background:#F6F8FA;
  width: 100%;
  text-align: center;
  padding: 0 300px;
  padding-top: 50px;
  padding-bottom: 100px;
  .title {
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #333333;
  }
  .tag {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    margin: 8px 0 19px 0;
  }
  .content {
    display: flex;
	
	.left_video {
		display: flex;
		flex: 1;
		height: 375px;
		background: #FFFFFF;
		border-radius: 8px;
		margin-right: 12px;
		position: relative;
		cursor: pointer;
		.news_view {
			flex: 1;
			padding: 25px 25px 0 25px;
			box-sizing: border-box;
		}
		.wrap_image {
			width: 100%;
			height: 200px;
			overflow: hidden;
			.news_cover_picture {
				// max-width: 100%;
				max-height: 100%;
				width: 100%;
				// object-fit: contain;
			}
		}
		.news_titles {
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			font-size: 20px;
			font-weight: 400;
			color: #222;
			line-height: 26px;
			padding-top: 16px;
			box-sizing: border-box;
			text-align: left;
		}
		.news_content {
			font-size: 14px;
			color: #999;
			line-height: 22px;
			width: 320px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			text-align: left;
			margin-top: 20px;
		}
	}
	.center_video {
		width: 240px;
		height: 375px;
		display: flex;
		align-content: space-between;
		flex-wrap: wrap;
		.wrap {
			height: 180px;
			width: 100%;
			border-radius: 8px;
			position: relative;
		}
	}
	.bottom_news {
		width: 480px;
		flex: 0 0 480px;
		height: 375px;
		background: #FFFFFF;
		border-radius: 8px;
		padding-left: 24px;
		padding-right: 30px;
		padding-top: 24px;
		box-sizing: border-box;
		.news_title {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			margin-bottom: 20px;
			.name {
				font-size: 24px;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 38px;
				color: #333333;
			}
			.more {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				cursor: pointer;
				color: #333333;
				.right_icon {
					color: #9E9E9E;
					font-size: 13px;
					margin-left: 3px;
				}
			}
		}
		.news_list {
			margin-bottom: 15px;
			display: flex;
			justify-items: center;
			cursor: pointer;
			.left_flex {
				width: 56px;
				flex: 0 0 56px;
				height: 56px;
				border-radius: 8px;
				margin-right: 10px;
				position: relative;
				.goods_img {
					width: 100%;
					height: 100%;
					border-radius: 8px;
					object-fit: cover;
				}
				.ranking_img {
					position: absolute;
					top: 0;
					left: -6px;
					width: 46px;
					height: 30px;
					z-index: 2;
				}
			}
			.right_news {
				flex: 1;
				text-align: left;
				padding: 5px 0;
				.list_title {
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: bold;
					line-height: 18px;
					color: #333333;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
				.list_text {
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 18px;
					margin-top: 10px;
					color: #999999;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
			}
		}
	}
	.video {
		width: 100%;
		height: 100%;
	}
	.text {
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		height: 56px;
		line-height: 56px;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 0px 0px 8px 8px;
		padding: 0 20px;
		text-align: left;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}
  }
}