.QC {
  .modal {
    .detail {
      display: flex;
      margin: 15px 0 40px 0;
      .left {
        .show_img {
          width: 300px;
          height: 410px;
        }
        .img_box {
          display: flex;
          align-items: center;
          width: 300px;
          overflow-x: auto;
          margin-top: 30px;
          img {
            width: 52px;
            width: 52px;
            margin-right: 10px;
            cursor: pointer;
          }
          img:nth-last-child(1){
            margin-right: 0px;
          }
        }
      }
      .right {
        margin-left: 25px;
        .goods_box {
          background: #F7F7F7;
          padding: 12px 0 12px 20px;
          .top {
            display: flex;
            align-items: center;
            .tag {
              background: #FF3232;
              border-radius: 100px;
              font-size: 12px;
              color: #FFFFFF;
              padding: 0 6px;
            }
            .name {
              width: 80%;
              font-size: 16px;
              font-weight: bold;
              color: #333333;
              margin-left: 8px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .bottom {
            color: #999999;
          }
        }
        .title_img {
          width: 100%;
          margin-top: 10px;
        }
        .info {
          overflow-y: auto;
          height: 267px;
          .info_box {
            .title {
              background: #F7F7F7;
              padding: 5px 0 5px 20px;
              color: #333333;
              font-weight: bold;
            }
            .infos {
              display: flex;
              flex-wrap: wrap;
              padding: 0 20px 15px 20px;
              justify-content: space-between;
              .text_box {
                width: 49%;
                margin-top: 15px;
                .flex_align {
                  display: flex;
                  align-items: center;
                  .origin_img {
                    max-width: 30px;
                    max-height: 30px;
                    margin-left: 10px;
                    cursor: pointer;
                  }
                }
                .text {
                  margin-left: 5px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
    .title {
      font-size: 14px;
      font-family: PingFang SC;
      color: #333;
    }
    .footer {
      background: #F7F7F7;
      display: flex;
      justify-content: space-between;
      padding-left: 20px;
      height: 55px;
      .left {
        display: flex;
        align-items: center;
        .price {
          // margin-left: 40px;
        }
      }
      .right {
        background: #FF3232;
        font-weight: bold;
        font-size: 20px;
        color: #fff;
        padding: 12px 40px;
        cursor: pointer;
      }
    }
  }
}  