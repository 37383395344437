.picker {
  background: #F6F8FA;
  width: 1800px;
  text-align: center;
  padding: 0 300px;
  padding-top: 50px;
  margin: 0 auto;
//   padding-bottom: 120px;
  .title {
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #191818;
	text-align: left;
	margin-bottom: 20px;
  }
  .tag {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    margin: 8px 0 19px 0;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .goods_box {
      cursor: pointer;
      width: 24%;
      background: #fff;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding: 48px 10px 30px 10px;
      .tag_img {
        position: absolute;
        top: 0;
        left: 0;
		width: 88px;
		height: 40px;
      }
	  .wrap_img {
		  width: 200px;
		  height: 200px;
		  display: flex;
		  justify-content: center;
		  align-items: center;
			.goods_img {
				width: 200px;
				height: 200px;
				object-fit: scale-down;
			}
	  }
      .name {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #242933;
        margin-top: 12px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		.text {
           max-width: 240px;
		   height: 28px;
		   overflow: hidden;
		   text-overflow: ellipsis;
		   display: -webkit-box;
		   -webkit-line-clamp: 1;
		   -webkit-box-orient: vertical;
		}
		.grade {
			width: 40px;
			height: 24px;
			// background: url('https://resources.huanhuanhuishou.com/global_source/9AEF87DF-3F51-5E85-7E35-83A65D73B41A.png') no-repeat center;
			// background-size: 100% 100%;
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 24px;
			text-align: center;
			color: #FFFFFF;
			margin-right: 10px;
			background: #E83A36;
			border-radius: 5px 5px 5px 5px;
		}
      }
      .sku_desc {
		  font-size: 14px;
		  font-family: PingFang SC;
		  font-weight: 400;
		  line-height: 21px;
		  color: #9A9393;
		  margin-top: 10px;
		  overflow: hidden;
		  text-overflow: ellipsis;
		  display: -webkit-box;
		  -webkit-line-clamp: 1;
		  -webkit-box-orient: vertical;
	  }
	  .price {
		  display: flex;
		  align-items: flex-end;
		  font-size: 14px;
		  font-family: PingFang SC;
		  font-weight: 400;
		  line-height: 21px;
		  color: #666666;
		  margin-top: 12px;
		  .icon_price {
			  font-size: 28px;
			  font-family: DIN;
			  font-weight: bold;
			  line-height: 21px;
			  color: #FF3232;
			  margin-left: 8px;
			  span {
				  font-size: 18px;
			  }
		  }
	  }
    }
  }
}