.wrap_list {
  background: #f8f8f8;
  padding-bottom: 60px;
  .news_list {
    width: 1226px;
    margin: 0 auto;
    line-height: 58px;
    .breadcrumb {
        font-size: 14px;
        font-weight: 700;
        color: #303133;
        line-height: 80px;
        .light {
            margin: 0 7px;
            color: #c0c4cc;
            margin-right: 4px;
        }
        .bread_color {
            font-weight: 400;
            color: #606266;
            cursor: text;
        }
        .cursor {
            cursor: pointer;
        }
    }
  }
  .newsC {
    width: 1226px;
    margin: 0 auto;
    position: relative;
    .navigation {
        width: 234px;
        height: auto;
        float: left;
        position: sticky;
        top: 0;
        background-color: #fff;
        .hotLeft {
            width: 234px;
            background-color: #fff;
            .hotList {
                width: 100%;
                height: 56px;
                padding: 0 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                position: relative;
                &:hover {
                   background: #ffebeb;
                }
                span {
                    font-size: 14px;
                    font-weight: 400;
                    color: #303133;
                    display: inline-block;
                }
                .elSubmenuIconArrow {
                    width: 8px;
                    height: 8px;
                    color: #909399;
                    transition: .3s;
                    font-size: 12px;
                    position: absolute;
                    top: 53%;
                    right: 20px;
                    margin-top: -7px;
                    transition: .3s;
                    &::before {
                        content: "";
                        width: 8px;
                        height: 8px;
                        border-left: 1px solid #909399;
                        border-top: 1px solid #909399;
                        position: absolute;
                        transform: rotate(225deg);
                    }
                }
                .elIconArrowDown {
                    transform: rotate(180deg);
                    &::before {
                        border-left: 1px solid #FF3232;
                        border-top: 1px solid #FF3232;
                    }
                }
            }
        }
        .hotListBox {
            width: 100%;
            background: #F4F4F4;
            .hotLli {
                height: 50px;
                line-height: 50px;
                font-size: 14px;
                font-weight: 400;
                color: #666;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
                width: 100%;
                display: block;
                padding: 0 45px;
                box-sizing: border-box;
                &:hover {
                   background: #ffebeb;
                }
            }
        }
    }
    .newsCRight {
        width: 972px;
        margin-left: 254px;
        box-sizing: border-box;
        .hotli {
            width: 972px;
            height: 204px;
            border-bottom: 1px solid #eee;
            box-sizing: border-box;
            list-style: none;
            position: relative;
            background: #fff;
            margin-bottom: 20px;
            cursor: pointer;
            .hotImg {
                width: 330px;
                height: 204px;
                float: left;
            }
            .hotlitext {
                width: 643px;
                height: 204px;
                position: relative;
                margin-left: 330px;
                padding-left: 26px;
                padding-right: 36px;
                box-sizing: border-box;
                .title {
                    font-size: 22px;
                    font-weight: 400;
                    white-space: nowrap;
                    color: #333333;
                    line-height: 100px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .introduction {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    color: #999;
                    margin-bottom: 22px;
                }
                .time {
                    display: flex;
                    justify-content: space-between;
                    color: #ccc;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
        .pageination {
            margin-top: 60px;
            text-align: center;
        }
    }
  }
}