.detail {
  font-family: PingFang SC;
  margin: 0 30px;
  .info_box {
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .left {
      background: #FF3232;
      position: relative;
      padding: 10px 50px 20px 50px;
      border-radius: 8px 0 0 8px;
      color: #fff;
      .tag {
        border-top-left-radius: 7.1px;
        border-bottom-right-radius: 8px;
        background: rgba(255, 255, 255,0.7);
        padding: 1px 10px;
        color: #F80303;
        position: absolute;
        top: 0;
        left: 0;
      }
      .time {
        display: flex;
        align-items: flex-end;
        margin-top: 18px;
        .txt {
          margin-left: 7px;
          padding-bottom: 2px;
        }
      }
      .text {
        margin-top: 6px;
        color: #fff;
      }
    }
    .right {
      .anpai {
        display: flex;
        align-items: center;
        padding: 50px;
        .box {
          display: flex;
          align-items: center;
          margin-right: 120px;
          position: relative;
          color: #999999;
          .value_box {
            position: absolute;
            top: 0;
            right: -100px;
          }
          .value {
            color: #333;
          }
          .tag {
            color: #E5E5E5;
          }
        }
      }
      .tongpai {
        display: flex;
        align-items: center;
        padding: 0 50px;
        .info {
          display: flex;
          align-items: center;
          // width: 70%;
          // flex-wrap: wrap;
          .box {
            margin: 5px 10px;
            margin-right: 30px;
            // width: 33%;
            display: flex;
            align-items: center;
            position: relative;
            color: #999999;
            .value_box {
              position: absolute;
              top: 0;
              right: 89px;
            }
            .value {
              color: #333;
            }
            .tag {
              color: #E5E5E5;
            }
          }
        }
      }
    }
  }
  .head_box{
    padding: 20px 30px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .filter_box {
      display: flex;
      align-items: center;
      .brand_box {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-right: 28px;
      }
      .btns {
        display: flex;
        align-items: center;
        margin-left: 100px;
      }
    }
  }
  .table_box {
    padding: 10px 30px;
    background: #fff;
    border-radius: 8px;
    :global {
      .table {
        .ant-table-thead > tr > th {
          font-weight: bold !important;
          background: #fff;
          text-align: center;
        }
        .ant-table-tbody > tr > td:nth-child(1) {
          max-width: 120px;
        }
        .ant-table-tbody > tr > td:nth-child(n+2) {
          text-align: center;
        }
      }
    }
  }
}