.information {
    padding: 25px 40px;
    background-color: #ffffff;
    .material {
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 29px;
        color: #333333;
        margin-bottom: 15px;
    }
}

.shipping_address {
    padding: 25px 40px;
    background-color: #ffffff;
    margin-top: 50px;
    .my_address {
        margin-bottom: 30px;
        span:nth-child(1) {
            font-size: 22px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 29px;
            color: #333333;
            margin-right: 20px;
        }
    }
    .methods {
        div {
            display: inline-block;
            width: 50%;
            margin-bottom: 25px;
        }
    }
}