.home {
  width: 1903px;
  background-color: #F6F8FA;
}

.footer_img {
  position: sticky;
  z-index: 100;
  img {
    width: 100%;
  }
  .close_bnt {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 50%;
    margin-top: -14px;
    right: 272px;
    cursor: pointer;
  }
}