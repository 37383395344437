.filter_box {
  display: flex;
  align-items: center;
  margin: 20px 0 40px 0;
  .brand_box {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-right: 28px;
  }
  .btns {
    display: flex;
    align-items: center;
    margin-left: 100px;
  }
}
:global {
  .table {
    .ant-table-thead > tr > th {
      font-weight: bold !important;
      text-align: center;
    }
    .ant-table-tbody > tr > td {
      text-align: center;
    }
  }
}