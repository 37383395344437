.login {
  width: 100%;
  display: flex;
  background-image: url('https://resources.huanhuanhuishou.com/new_image/20210819/hhyp_bg_login.png');
  background-size: 100% 100%;
  .box {
    width: 65.73%;
    height: 100vh;
  }
  .login_box {
    background: #fff;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      margin: 19px 0 9px 0;
      font-size: 25px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .input_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 53.5%;
      border: 1px solid #eee;
      border-radius: 24px;
      box-shadow:0 0 9px 3px #eee;
      padding: 6px 10px;
      margin-top: 18px;
      padding-right: 20px;
      .code {
        width: 30%;
        display: flex;
        justify-content: flex-end;
        .text {
          cursor: pointer;
        }
      }
    }
    .btn {
      width: 53.5%;
      border-radius: 24px;
      margin-top: 52px;
      background: #FF3232;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      padding: 10px 0;
      cursor: pointer;
    }
  }
}