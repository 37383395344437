.head_box{
  padding: 20px 30px;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  .filter_box {
    display: flex;
    align-items: center;
    .brand_box {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-right: 28px;
    }
    .btns {
      display: flex;
      align-items: center;
      margin-left: 100px;
    }
  }
}