.bidding_price {
  padding: 20px 40px;
  position: relative;
  :global {
    .table {
      .ant-table-thead > tr > th {
        font-weight: bold !important;
        background: #fff;
        text-align: center;
      }
      .ant-table-thead > tr > th:nth-child(1) {
        width: 90px;
      }
      .ant-table-thead > tr > th:nth-child(2) {
        width: 400px;
      }
      .ant-table-tbody > tr > td:nth-child(n+3) {
        text-align: center;
      }
    }
  }
  .all_select {
    position: absolute;
    font-weight: bold !important;
    background: #fff;
    text-align: center;
    left: 100px;
    top: 35px;
  }
  .all_option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    background: #fff;
    padding-left: 16px;
    .left {
      .all {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 15px;
        color: #333333;
        opacity: 1;
      }
      .clear {
        font-size: 14px;
        margin-left: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #1e9fff;
        cursor: pointer;
      }
    }
    .right {
      display: flex;
      font-size: 14px;
      align-items: center;
      height: 100%;
      font-family: PingFang SC;
      font-weight: 400;
      .select_goods {
        color: #333333;
        .num {
          color: #ff3232;
        }
      }
      .total {
        margin: 0 20px 0 40px;
      }
      .price {
        color: #ff3232;
      }
      .settlement {
        margin-left: 30px;
        width: 120px;
        height: 100%;
        background: #FF3232;
        text-align: center;
        line-height: 52px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        cursor: pointer;
      }
      .settlement_none {
        margin-left: 30px;
        width: 120px;
        height: 100%;
        background: #cccccc;
        text-align: center;
        line-height: 52px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }
}
