.wrap_list {
  background: #f8f8f8;
  padding-bottom: 60px;
  .news_list {
    width: 1226px;
    margin: 0 auto;
    line-height: 58px;
    .breadcrumb {
        font-size: 14px;
        font-weight: 700;
        color: #303133;
        line-height: 80px;
        .light {
            margin: 0 7px;
            color: #c0c4cc;
            margin-right: 4px;
        }
        .bread_color {
            font-weight: 400;
            color: #606266;
            cursor: text;
        }
        .cursor {
            cursor: pointer;
        }
    }
  }
  .newsC {
    width: 1226px;
    margin: 0 auto;
    position: relative;
    .navigation {
        width: 234px;
        height: auto;
        float: left;
        position: sticky;
        top: 0;
        background-color: #fff;
        .hotLeft {
            width: 234px;
            background-color: #fff;
            .hotList {
                width: 100%;
                margin-bottom: 20px;
                h5 {
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    display: inline-block;
                    margin-top: 26px;
                    margin-left: 21px;
                    line-height: 20px;
                }
            }
        }
        .hotListBox {
            width: 100%;
            .hotLli {
                font-size: 14px;
                line-height: 35px;
                font-weight: 400;
                color: #666;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
                width: 100%;
                display: block;
                margin-bottom: 14px;
                padding: 0 20px;
                box-sizing: border-box;
                &:hover {
                    color: #FF3232;
                }
            }
        }
    }
    .newsCRight {
        width: 972px;
        margin-left: 254px;
        box-sizing: border-box;
        .newsCRightTop {
            width: 100%;
            padding: 0 50px 60px 40px;
            box-sizing: border-box;
            background-color: #fff;
            .newsCRHeader {
                font-size: 24px;
                // line-height: 25px;
                color: #000;
                padding: 30px 0 11px;
                display: block;
                font-weight: 600;
            }
            .timefrom {
                text-align: left;
                box-sizing: border-box;
                padding-bottom: 10px;
                box-sizing: border-box;
                margin: 0 0 20px;
                font-size: 14px;
                color: #999;
                span {
                    margin: 0 20px;
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
        .updown {
            width: 100%;
            background: #fff;
            .con {
                width: 100%;
                padding: 34px;
                box-sizing: border-box;
                .aboutInfo {
                    width: 100%;
                    .information {
                        width: 100%;
                        line-height: 22px;
                        box-sizing: border-box;
                        font-size: 22px;
                        font-weight: 500;
                        color: #333333;
                        margin: 0  0 30px 0;
                    }
                    .ul {
                        width: 100%;
                        .h2 {
                            width: 437px;
                            height: 66px;
                            background-color: #fff;
                            display: inline-block;
                            padding-right: 24px;
                            box-sizing: border-box;
                            margin-bottom: 24px;
                            cursor: pointer;
                            &:hover {
                                .name {
                                    color: #FF3232;
                                }
                            }
                            .name {
                                color: rgb(51, 51, 51);
                                margin-right: 25.2px;
                                font-size: 14px;
                                line-height: 14px;
                                font-weight: 400;
                                color: #333333;
                                width: 100%;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                margin-top: 15px;
                                margin-bottom: 7px;
                                overflow: hidden;
                            }
                            .date {
                                font-size: 14px;
                                line-height: 14px;
                                font-weight: 400;
                                color: #999999;
                            }
                        }
                    }
                }
            }
        }
    }
  }
}